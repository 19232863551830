import { PrimaryGuard } from "../../core/guards/primary.guard";
import { SocketGuard } from "../../core/guards/socket.guard";
import { SyncGuard } from "../../core/guards/sync.guard";
import { ShopRestrictedGuard } from "../../guards/shop-restricted.guard";
import { APP_ROUTING_PATH } from "../routing-app.const";
import {ConfirmationDeliveryGuard} from '../../guards/confirmation-delivery.guard';
import {NewEditionGuard} from '../../guards/new-edition.guard';
import {GalleryGuard} from '../../modules/shared/guards/custom/gallery.guard';
import {ShopGuard} from '../../modules/shared/guards/custom/shop.guard';
import { PlayerChooseComponent } from "../../modules/auth/base/custom/components/player-choose/player-choose.component";
import {NewEditionComponent} from '../../modules/game/game-ui/hud/custom/components/new-edition/new-edition.component';
import {
  ConfirmationDeliveryComponent
} from '../../modules/game/game-ui/hud/custom/components/confirmation-delivery/confirmation-delivery.component';

export const CUSTOM_ROUTING_APP_CHILDREN = [
  {
    path: APP_ROUTING_PATH.GAME,
    canActivate: [SyncGuard],
    data: {
      syncGuards: [PrimaryGuard, SocketGuard, ConfirmationDeliveryGuard, ShopRestrictedGuard, NewEditionGuard],
    },
    loadChildren: () => import("../../modules/new-game/module/core/game.module").then(m => m.GameModule),
  },
  {
    path: 'gallery',
    canActivate: [SyncGuard],
    data: {
      syncGuards: [
        PrimaryGuard,
        SocketGuard,
        ConfirmationDeliveryGuard,
        NewEditionGuard,
        GalleryGuard,
      ]
    },
    loadChildren: () => import('../../modules/photo-gallery/module/core/photo-gallery.module').then(m => m.PhotoGalleryModule),
  },
  {
    path: APP_ROUTING_PATH.PLAYER_CHOOSE,
    component: PlayerChooseComponent,
  },
  {
    path: 'shop',
    canActivate: [SyncGuard],
    data: {
      syncGuards: [
        PrimaryGuard,
        SocketGuard,
        ConfirmationDeliveryGuard,
        NewEditionGuard,
        ShopGuard,
      ]
    },
    loadChildren: () => import('../../modules/shop/shop.module').then(m => m.ShopModule),
  },
  {
    path: 'endgame',
    canActivate: [SyncGuard],
    data: {
      syncGuards: [
        PrimaryGuard,
        SocketGuard,
        ConfirmationDeliveryGuard,
      ]
    },
    component: NewEditionComponent
  },
  {
    path: 'confirmation-delivery',
    canActivate: [PrimaryGuard, SocketGuard],
    component: ConfirmationDeliveryComponent,
  },
];
