<div class="global-dialog ow-dialog c-window-long league-container">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button
    *ngIf="!data?.isOpenFromQuarter"
  ></m-ui-back-button>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Liga sprzedaży</span>
      </div>
    </div>

    <div class="source-data-date-tab" *ngIf="owPaginationDate && !isLoading && league?.data_created_at as date">
      Dane aktualne na: {{ date | date:'dd-MM-y' }}
    </div>
  </div>

  <!-- NAVIGATION -->
  <div class="pagination loading-opacity" *ngIf="owPaginationDate && !isLoading">
    <!-- NAVIGATION MONTH -->
    <ng-container *ngIf="activeTabLeft.index === LEAGUE_TYPE.MONTH">
      <button (click)="prev()" [disabled]="!owPaginationDate.allowPrev.month">
        <i class="far fa-chevron-square-left nav-arrow"></i>
      </button>

      <div class="value">
        <div class="month">
          {{ owPaginationDate.current.month_text_normal }}
        </div>
      </div>

      <div
        [matTooltip]="'Dane dostępne wkrótce.'"
        [matTooltipPosition]="'above'"
        [matTooltipDisabled]="owPaginationDate.allowNext.month"
      >
        <button
          (click)="next()"
          [disabled]="!owPaginationDate.allowNext.month"
        >
          <i class="far fa-chevron-square-right nav-arrow"></i>
        </button>
      </div>
    </ng-container>

    <!-- NAVIGATION QUARTER -->
    <ng-container *ngIf="activeTabLeft.index === LEAGUE_TYPE.QUARTER">
      <button (click)="prev()" [disabled]="!owPaginationDate.allowPrev.quarter">
        <i class="far fa-chevron-square-left nav-arrow"></i>
      </button>

      <div class="value">
        <div class="month">
          {{ owPaginationDate.current.quarter_text_normal }}
        </div>
      </div>

      <button (click)="next()" [disabled]="!owPaginationDate.allowNext.quarter">
        <i class="far fa-chevron-square-right nav-arrow"></i>
      </button>
    </ng-container>

    <ng-container *ngIf="!prevResults">
      <button class="quality" (click)="openQuality()">
        <img [src]="'icon_quality.png' | asset: 'ui'">
      </button>

      <button *ngIf="owDate.year !== 2025" class="chart" (click)="openRanks()">
        <i class="fas fa-chart-line"></i>
      </button>
    </ng-container>
  </div>

  <ng-container *ngIf="league && !isLoading; else noItemTpl;">

    <!-- SUMMARIES -->
    <div class="summaries loading-opacity">
      <!-- PREDICTION -->
      <div class="summary-row" *ngIf="league.prediction as prediction">
        <div class="summary-title">
          <span>{{ league.is_cleared ? 'wynik' : 'prognoza' }}</span>
        </div>
        <button (click)="openAlert(tooltipDialogs.prediction)">
          <i class="fas fa-info"></i>
        </button>
        <div class="summary-blocks frame">
          <div class="block">
            <div class="item" *ngIf="playerService.player?.title_id.id < PLAYER_TITLES.DOR.id || !!branchName">
              <div class="txt">
                Grupa
              </div>
              <div
                *ngIf="leagueGroup"
                class="icon-league group small"
                [ngClass]="'icon-' + leagueGroup"
              ></div>
            </div>
            <div class="item">
              <div class="txt">
                Miejsce:
              </div>
              <div class="number">
                {{ isDor && !branchName ? (prediction.position || '-') : (prediction.league_group_pos || '-') }}
              </div>
            </div>
            <div class="item">
              <div class="txt">
                Punkty:
              </div>
              <div class="number">
                {{ (prediction.position_points | number) || '-' }}
              </div>
            </div>
          </div>
          <div class="block">
            <div class="item">
              <div
                class="icon-league"
                [ngClass]="'league-' + prediction.color"
                [ngbTooltip]="'Liga ' + (prediction.color | businessLeagueColor).name"
                placement="bottom"
              ></div>
            </div>
            <div class="item">
              <div class="txt">
                Punkty:
              </div>
              <div class="number">
                {{ (prediction.color_points | number) || '-'}}
              </div>
            </div>
          </div>
          <div class="block info">
            <div class="item">
              <div class="txt" *ngIf="!isDor">
                Sięgnij nawet po
                {{ activeTabLeft.index == LEAGUE_TYPE.MONTH ? '10000' : '22000' }}
                żetonów ligowych – sprawdź szczegóły w pliku podpiętym
                <a
                  target="_blank"
                  href="https://santao.santanderconsumer.pl/api/files/download/4e8a327e-0606-4313-8d0a-ee68e4930d12/SanTao_Liga_Sprzeda%C5%BCy.pdf"
                >
                  <b>TUTAJ.</b>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ESTIMATION -->
      <div class="summary-row" *ngIf="league.estimation as estimation">
        <div class="summary-title">
          <span>symulacja</span>
        </div>
        <button (click)="openAlert(!branchName || playerService.player.id === managerPlayerId ? tooltipDialogs.estimation : tooltipDialogs.notAvailable)">
          <i class="fas fa-info"></i>
        </button>
        <div class="summary-blocks frame">
          <ng-container *ngIf="playerService.isActiveMe && allowEstimation; else lockEstimationTpl">
            <div class="block">
              <div class="item" *ngIf="playerService.player?.title_id.id < PLAYER_TITLES.DOR.id">
                <div class="txt">
                  Grupa
                </div>
                <div
                  *ngIf="leagueGroup"
                  class="icon-league group small"
                  [ngClass]="'icon-' + leagueGroup"
                ></div>
              </div>
              <div class="item">
                <div class="txt">
                  Miejsce:
                </div>
                <div class="number">
                  {{ isDor ? (estimation.position || '-') : (estimation.league_group_pos || '-') }}
                </div>
              </div>
              <div class="item">
                <div class="txt">
                  Punkty:
                </div>
                <div class="number">
                  {{ (estimation.position_points | number) || '-' }}
                </div>
              </div>
            </div>
            <div class="block">
              <div class="item">
                <div
                  class="icon-league"
                  [ngClass]="'league-' + estimation.color"
                  [ngbTooltip]="'Liga ' + (estimation.color | businessLeagueColor).name"
                  placement="bottom"
                ></div>
              </div>
              <div class="item">
                <div class="txt">
                  Punkty:
                </div>
                <div class="number">
                  {{ (estimation.color_points | number) || '-' }}
                </div>
              </div>
            </div>
            <div class="block">
              <div class="item">
              </div>
            </div>
          </ng-container>
          <ng-template #lockEstimationTpl>
              <span class="disallow-estimation">
                 {{ playerService.isActiveMe && !branchName ? 'Symulacja wstecz jest niedostępna' : 'Podgląd symulacji innego gracza jest zablokowany' }}
              </span>
          </ng-template>
        </div>
      </div>
    </div>

    <div class="settings loading-opacity">
      <div class="radio-buttons-wrapper">
        <ng-container *ngFor="let tab of tabsLeft">
          <div
            *ngIf="tab.visible"
            class="control"
            (click)="changeTabLeft(tab)"
          >
            <label class="choose-wrapper">
              <span class="checkbox-wrapper">
               <input
                 type="radio"
                 id="monthly"
                 name="leaguePeriod"
                 [checked]="tab == activeTabLeft"
               >
                <span class="checkmark"></span>
              </span>
              <span class="text-wrapper">
                {{ tab.label }}
              </span>
            </label>
          </div>
        </ng-container>
      </div>
      <div class="default-settings">
        <div
          class="link"
          (click)="deleteEstimations()"
          *ngIf="playerService.isActiveMe && allowEstimation"
        >
          <span>przywróć wartości domyślne</span>
          <i class="fas fa-sync-alt"></i>
        </div>
      </div>
    </div>

    <div class="table-wrapper loading-opacity">
      <div class="frame">
        <ow-perfect-scrollbar [scrollGradient]="league.tasks.length">
          <table>
            <thead>
            <tr>
              <th></th>
              <th>Zadania Bonusowe</th>
              <th>Waga Liga</th>
              <th>Wykonanie</th>
              <th>Prognoza</th>
              <th *ngIf="!branchName || playerService.player.id === managerPlayerId">Symulacja</th>
              <th *ngIf="!branchName || playerService.player.id === managerPlayerId" class="text-right">
                <button (click)="openAlert(!branchName || playerService.player.id === managerPlayerId ? tooltipDialogs.estimationInfo : tooltipDialogs.notAvailable)">
                  <i class="fas fa-info"></i>
                </button>
              </th>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let task of league.tasks">
              <tr>
                <td>{{ task.name }}</td>
                <td
                  class="bonus-task"
                  [matTooltip]="'Poziom wykonania zadania: ' + (task.performance | number:'1.2-2') + '% / Wykonanie w '
                  + (!isDor ? 'grupie ligowej' : 'w kraju') + ': ' + task.revision_condition_result + '%'"
                  matTooltipPosition="above"
                  matTooltipShowDelay="300"
                  [matTooltipDisabled]="task.bonus_type_id == 0 || !task.is_after_revision"
                  [ngClass]="task.is_after_revision && (task.is_bonus_met ? 'green' : 'red')"
                >
                  {{ (task.bonus_type_id | businessLeagueBonusTask).name }}
                </td>
                <td>{{ (task.weight * 100).toPrecision(6) | number:'1.0-4' }}%</td>
                <td>
                  <div class="progressbar-wrapper">
                    <div class="progressbar">
                      <div class="fill fill-color-1" [style.width]="task.performance + '%'"></div>
                    </div>
                    <div
                      [matTooltip]="'Nastąpiło podbicie do 100% z poziomu ' + (task.forecast| number:'1.2-2') + '%'"
                      matTooltipPosition="above"
                      matTooltipShowDelay="300"
                      [matTooltipDisabled]="!(task.is_revision_met && league.is_cleared)"
                      class="number"
                    >
                      {{ task.performance | number:'1.2-2' }}%
                    </div>
                  </div>
                </td>
                <td>
                  <div class="progressbar-wrapper">
                    <div class="progressbar">
                      <div class="fill fill-color-3" [style.width]="task.forecast + '%'"></div>
                    </div>
                    <div class="number">{{ task.forecast | number:'1.2-2'}}%</div>
                  </div>
                </td>
                <ng-container *ngIf="playerService.isActiveMe && allowEstimation; else lockEstimationTableTpl">
                  <td class="ow-slider-td">
                    <ow-slider *ngIf="task.forecast_max"
                               [min]="task.forecast_min"
                               [max]="task.forecast_max"
                               [value]="task.forecast_estimation"
                               (onInput)="slideEvent(task, $event)"
                               (onChange)="onChange(task, $event)"
                               step="1"
                    >
                    </ow-slider>
                  </td>
                </ng-container>
                <ng-container *ngIf="playerService.isActiveMe && allowEstimation; else lockEstimationTableTpl">
                  <td>
                    <div class="input-percentage">
                      <input
                        type="number"
                        [value]="task.forecast_estimation"
                        (blur)="onChange(task, $event)"
                        (keyup.enter)="onChange(task, $event)
                    "/>%
                    </div>
                  </td>
                </ng-container>
              </tr>
              <ng-template #lockEstimationTableTpl>
                <td *ngIf="!branchName || playerService.player.id === managerPlayerId">-</td>
              </ng-template>
            </ng-container>
            </tbody>
          </table>
        </ow-perfect-scrollbar>
      </div>
    </div>
  </ng-container>

  <ng-template #noItemTpl>
    <div *ngIf="!league && !isLoading && !prevResults && owDate.year !== 2025" class="no-item">
      Brak danych
    </div>

    <div *ngIf="!league && !isLoading && !prevResults && owDate.year === 2025" class="no-item">
      <div class="prev-results">
        <div class="desc text-center">
          Od stycznia 2025 nowe zasady w Lidze Sprzedaży. <br/>
          Zapoznaj się ze szczegółami
          <a [href]="'https://santao.santanderconsumer.pl/api/files/download/325592be-ea92-44d8-b980-95861862f6c8/Liga_Sprzeda%C5%BCy_2025_opis_zmian_2025.pdf'" target="_blank">TUTAJ</a>
        </div>
      </div>
    </div>

    <div class="prev-results" *ngIf="prevResults">
      <div class="desc">
        Sprawdź
        <a [href]="prevResultsHref" target="_blank">TUTAJ</a>
        wyniki ligowe za <span>{{ owDate.month_text_normal }}</span>!
      </div>

      <div class="logo-league"></div>
    </div>
  </ng-template>

  <div *ngIf="league?.is_cleared" class="cleared"></div>
  <div *ngIf="branchName" class="branch-name">{{ branchName }}</div>
</div>
