<div class="global-dialog ow-dialog c-window-long">
  <loading></loading>

  <!-- DIALOG BACK -->
  <m-ui-close-button></m-ui-close-button>

  <div *ngIf="businessCalendarAvailable" (click)="openBusinessCalendar()" class="business-calendar-button"></div>

  <!-- BOOKMARKS -->
  <div class="bookmarks">
    <div class="overlap active green">
      <div class="bookmark green">
        <span>Zadania biznesowe</span>
      </div>
    </div>

    <div class="source-data-date-tab" *ngIf="tasksSourceDataDate">
      Dane aktualne na: {{ tasksSourceDataDate | date:'dd-MM-y' }}
    </div>
  </div>

  <!-- TOP MENU -->
  <div class="top-menu loading-opacity">
    <div class="option">
      <button
        class="c-square small c-secondary"
        matTooltip="Dostępne wkrótce!"
        matTooltipPosition="below"
        [matTooltipDisabled]="playerService.player.gui_unlocks['rankings']"
        (click)="openRanks()"
      >
        <i class="fas fa-chart-line"></i>
      </button>
      <span>Rankingi</span>

      <div
        class="app-notification dot-blue"
        *ngIf="player['gui_notifications']?.['import_calculate_ranks'] > 0"
      ></div>
    </div>

    <!-- EVENTS -->
    <div class="option">
      <button
        class="c-square small c-secondary"
        matTooltip="Dostępne wkrótce!"
        matTooltipPosition="below"
        [matTooltipDisabled]="player.gui_unlocks['business_events']"
        (click)="openEvents()"
      >
        <i class="fas fa-gift"></i>
      </button>
      <span>Eventy</span>

      <div
        class="app-notification dot-red"
        *ngIf="player['gui_notifications']?.['business_event'] > 0"
      ></div>
    </div>

    <div
      class="option"
      *ngIf="isPlayable && playerService.player.gui_unlocks['micro_league_cc']"
    >
      <button
        class="c-square small c-quaternary"
        matTooltip="Dostępne wkrótce!"
        matTooltipPosition="below"
        [matTooltipDisabled]="playerService.player.gui_unlocks['micro_league_cc']"
        (click)="openMicroLeague()"
      >
        <i class="fas fa-medal"></i>
      </button>
      <span>Mikroliga</span>

      <div
        class="app-notification dot-blue"
        *ngIf="player['gui_notifications']?.['import_calculate_microleague'] > 0"
      ></div>
    </div>

    <div
      class="option"
      *ngIf="isPlayable"
    >
      <button
        class="c-square small c-secondary"
        matTooltip="Dostępne wkrótce!"
        matTooltipPosition="below"
        [matTooltipDisabled]="playerService.player.gui_unlocks['basic_actions']"
        (click)="openBasicActions()"
      >
        <i class="fas fa-coins"></i>
      </button>
      <span>Akcje <br> podstawowe</span>

      <div
        class="app-notification dot-blue"
        *ngIf="isPlayable && player['gui_notifications']?.['import_calculate_basic_actions'] > 0"
      ></div>
    </div>

    <div
      class="option"
      *ngIf="isPlayable && !hideQualityTasks"
    >
      <div
        class="img pointer"
        [matTooltip]="playerService.player.gui_unlocks['dashboard_quality'] ? 'Organizacja pracy' : 'Organizacja pracy - dostępne wkrótce!'"
        matTooltipPosition="below"
        (click)="openQualityTasks()"
      >
        <img alt="" [src]="'icon_quality.png' | asset: 'ui'">
      </div>
    </div>
  </div>

  <!-- CONTENT -->
  <div
    *ngIf="!playerService.player.gui_unlocks['dashboard_under_construction'] && cardsTasks"
    class="main loading-opacity card-tasks-cc"
  >
    <ow-perfect-scrollbar
      *ngIf="cardsTasks.length > 0; else noItemTpl"
      [scrollGradient]="cardsTasks?.length"
    >
      <div class="container-card" *ngFor="let card of cardsTasks">
        <span class="animal-icon"></span>
        <div
          class="card"
          [class.is-choosable]="card.is_choosable && !card.position"
          [class.is-choosable-active]="card.is_choosable && card.position"
          (click)="openCardTaskDetails(card)"
          [ngClass]="'card-template-' + card.card_template_id"
        >
          <img *ngIf="card.card_template_id === CARD_TEMPLATE.CC_CHI" class="cc-chi" alt="" [src]="'cc_chi.png' | asset: 'ui'">

          <!-- NAME -->

          <div class="card-name">
            {{ card.name }}

            <span class="is-choosable-icon">
              <i class="fas fa-check-square"></i>
            </span>
          </div>

          <!-- PROGRESSBAR -->
          <div class="progressbar-container">
            <ng-container *ngIf="card.performance_percent !== null">
              <div class="progressbar">
                <div
                  class="fill"
                  [style.width]="(card.performance_percent / card.scope1 * 100) + '%'"
                  [ngClass]="card.color_id"
                ></div>
              </div>
              <div class="box">
                <div class="progressbar-value">
                  {{ card.performance_percent }}<ng-container *ngIf="card.card_template_id !== CARD_TEMPLATE.HIDE_PERCENTAGE">{{ card.suffix_performance_formatted }}</ng-container>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- PRIZES -->
          <div class="card-prizes">
            <ng-container *ngIf="card.scopes && card.scopes[card.keyPercentage]">
              <ng-container *ngFor="let prize of card.scopes[card.keyPercentage].prizes">
                <m-ui-currency
                  *ngIf="prize['type'] === 'currency'"
                  [item]="prize"
                  [lack]="false"
                  [stockView]="STOCK_VIEW.H"
                ></m-ui-currency>

                <m-ui-product
                  *ngIf="prize['type'] === 'product'"
                  [item]="prize"
                  [lack]="false"
                  [stockView]="STOCK_VIEW.H"
                  [lockOpenStorage]="true"
                ></m-ui-product>
              </ng-container>
            </ng-container>
          </div>

          <!-- CARD TAB -->
          <div class="tab" [ngClass]="card.color_id"></div>
        </div>
      </div>
    </ow-perfect-scrollbar>

    <ng-template #noItemTpl>
      <div class="no-item">
        Brak danych
      </div>
    </ng-template>
  </div>

  <div class="main loading-opacity" *ngIf="playerService.player.gui_unlocks['dashboard_under_construction']">
    <div class="under-construction">
      <img alt="" [src]="'business.png' | asset:'ui'"/>
      Już w kwietniu poznasz szczegóły dotyczące Twoich zadań biznesowych!
    </div>
  </div>

  <div class="navigation">
    <!-- PREV -->
    <button
      class="previous"
      [disabled]="!owPaginationDate.allowPrev.month"
      (click)="prev()"
    >
      <i class="far fa-chevron-square-left nav-arrow"></i>
    </button>

    <!-- MONTH -->
    <span class="value">
      <ng-container *ngIf="owDate">
        {{ owDate.month_text_normal }}
      </ng-container>
    </span>

    <!-- NEXT -->
    <button
      class="next"
      [disabled]="!owPaginationDate.allowNext.month"
      (click)="next()"
    >
      <i class="far fa-chevron-square-right nav-arrow"></i>
    </button>
  </div>
</div>
