import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { GAME_CONFIG } from "../../../../../../../../core/config/custom/_parsed-game.config";
import {STOCK_VIEW} from '../../../../../shared-ui/mobile/consts/stock-view.const';
import {Store} from '@ngrx/store';
import {unsubscribeObject} from '../../../../../../../../core/utility/unsubscribe-array';
import {Subscription} from 'rxjs';
import {selectPlayer} from '../../../../../../../../store/player/selectors';
import {Swiper, SwiperOptions} from 'swiper/types';
import {swiperWfInjectionStyles} from '../../../../../../../../../styles/swiper/swiper-injection-styles';
import {WfPieceDetailsComponent} from '../../dialogs/wf-piece-details/wf-piece-details.component';
import {WfPiecePairingComponent} from '../../dialogs/wf-piece-pairing/wf-piece-pairing.component';
import {WfEntry, WfEntryIsland} from '../../../../interfaces/wf.interface';
import {WfService} from '../../../../module/custom/wf.service';
import {ProductDetails, ProductDetailsBalance} from '../../../../../../../player/interfaces/product.interface';
import {BoardService} from '../../../../../../services/board.service';
import {distinctUntilChanged, take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {ActionTypes} from '../../../../../../../../store/game/actions';
import {CurrencyBalanceDetails} from '../../../../../../../../core/interfaces/currency';
import {BoardTileState} from '../../../../../../../../store/game/interfaces/board-tile.state';
import {selectPrimary} from '../../../../../../../../store/primary/selectors';
import {Primary} from '../../../../../../../../core/interfaces/primary';
import { selectTokenObject } from '../../../../../../../../store/utility/selectors';

type MahjongStateInterface = {
  points: Partial<CurrencyBalanceDetails>,
  decorations: {
    box: number[],
    lines: number[]
  },
  boards: {[key: number]: { tiles: BoardTileState[], entryData: WfEntryIsland}},
  availableBoards: number[],
  products: { [key: number]: ProductDetailsBalance},
  isBlocked: boolean
  bottomPieces: ProductDetails[],
  specialCurrencyPieces: CurrencyBalanceDetails[],
  isLoaded: boolean;
  isImpersonated: boolean;
}

@Component({
  selector: "wf-mahjong",
  templateUrl: "./wf-mahjong.component.html",
})
export class WfMahjongComponent extends AbstractInjectBaseComponent implements OnInit, OnDestroy {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(Store) store: Store;
  @OwInject(WfService) wfService: WfService;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  @OwInject(BoardService) boardService: BoardService;
  @OwInject(Actions) storeActions: Actions;
  @Input() episodeData: WfEntry = null;
  @Output() updateEntries: EventEmitter<boolean> = new EventEmitter<boolean>(null);

  isCC = GAME_CONFIG.IS_CC;
  primary: Primary = null;

  state: MahjongStateInterface = {
    points: null,
    decorations: {
      box: [0, 1, 2],
      lines: [0, 1, 2, 3]
    },
    boards: {},
    availableBoards: [],
    bottomPieces: null,
    specialCurrencyPieces: [],
    products: {},
    isBlocked: false,
    isLoaded: false,
    isImpersonated: false
  }

  subs: { player: Subscription, storeActions: Subscription } = {
    player: null,
    storeActions: null
  }

  swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    centeredSlides: false,
    injectStyles: [swiperWfInjectionStyles],
    spaceBetween: '6px'
  }
  initSwiper = false;
  swiper: Swiper = null;
  @ViewChild("swiperRef", { static: false }) swiperRef?: ElementRef;


  ngOnInit() {
    this.state.isBlocked = this.episodeData.isFinished;
    this.store.select(selectTokenObject).pipe(take(1)).subscribe(res => {
      this.state.isImpersonated = res.is_impersonate;
    })

    this.store.select(selectPrimary).pipe(take(1)).subscribe(res => {
      this.primary = res;
    })

    this.subs.player = this.store.select(selectPlayer).pipe(distinctUntilChanged()).subscribe(res => {
      const { product_balances, currency_balances, ...rest } = res;

      // update victory points and piece stone_41 and stone_42 <= joker and replacement piece
      const chiDetails = this.primary.currencies.find(x => x.key === 'victory');
      this.state.points = { balance: (rest?.['chi_points'] ?? 0), ...chiDetails };

      // get special tiles on the bottom - replacing piece varies depending on the episode
      const specialCurrencies = [];

      // determine jokey key; 32860#note-3
      let jokerKey = this.episodeData.entry_id <= 3 ? 'stone_41' : 'stone_46';

      // find joker
      const joker = currency_balances.find(x => x.key.toLowerCase() === jokerKey);
      if (joker) {
        specialCurrencies.push(joker.currency_id);
      }
      specialCurrencies.push(this.episodeData.replace_currency_id);
      this.state.specialCurrencyPieces = JSON.parse(JSON.stringify(currency_balances.filter(x => specialCurrencies.includes(x.currency_id) && x.balance > 0
      )));

      // update products
      this.state.products = {};
      for (let product of product_balances) {
        this.state.products[product.product_id] = {...product};
      }
      this.getBottomPieces();
    })

    this.subs.storeActions = this.storeActions
      .pipe(ofType(ActionTypes.BOARD_TILE_UPDATE))
      .subscribe((res: { type: string; payload: BoardTileState }) => {
        // if store gets the information about tile refresh it will check if the tile exists in mahjong board, and if so - update it
        this.updateSingleBoardTile(res.payload, true);
      });

    this.getBoards(this.episodeData.island_details[0]);
  }

  /* boards */
  getBoards(island: WfEntryIsland) {
    this.state.boards[island.player_island_id] = {
      entryData: island,
      tiles: []
    };
    const quickRef = this.state.boards[island.player_island_id];
    this.state.availableBoards.push(quickRef.entryData.player_island_id);

    this.boardService.getBoard(this.playerService.getMePlayerId(), quickRef.entryData.player_island_id).subscribe(res => {
      const tiles = [];
      for (let x = 1; x < 6; x++) {
        for (let y = 1; y < 6; y++) {
          const tile = res.find(tile => tile.x_pos == x && tile.y_pos == y);
          tiles.push(tile ? tile : { emptyTile: true })
        }
      }
      quickRef.tiles = tiles;

      // the islands should trigger one by one
      if (Object.keys(this.state.boards).length < 3) {
        this.getBoards(this.episodeData.island_details[Object.keys(this.state.boards).length]);
      } else {
        // otherwise marked for "Ready" check
        this.checkIfGameIsReady();
      }
    })
  }

  // opens the dialog for pairing tile(build upgrade)
  showPairDialog(piece: BoardTileState) {
    if (piece.player_building == null || this.state.isBlocked) {
      return;
    }

    this.dialogService.open(WfPiecePairingComponent, {
      data: {
        details: piece,
        playerId: this.playerService.getMePlayerId()
      }
    }, (result: {isPaired?: boolean}) => {
      if (result?.isPaired) {
        this.updateSingleBoardTile(piece, result.isPaired);
      }
    })
  }

  // refresh board after pairing the piece
  updateSingleBoardTile(piece: BoardTileState, justHide: boolean) {
    for (let board of Object.values(this.state.boards)) {
      const orgPieceIndex = board.tiles.findIndex(x => x.tile_id === piece.tile_id);
      if (orgPieceIndex !== -1) {
        if (justHide) {
          // hide before the refresh
          board.tiles[orgPieceIndex]['hidden'] = true;

          // if this is the last piece on the board and there's still something to win then decrement amount of awards
          if (board.entryData.prize_amount_left > 0 && board.tiles.length > 0) {
            board.entryData.prize_amount_left--;
            this.updateEntries.emit(true);
          }
        } else {
          // if this is update then replace
          board.tiles[orgPieceIndex] = piece;
        }
      }
    }
  }

  // checks if the game is ready, if so it triggers swiper refresh and removes is-loading opacity/blur
  // we have to hide it permanently after the initial load; otherwise it will double show'n'hide this blur every update(two messages from WS)
  checkIfGameIsReady() {
    if (Object.keys(this.state.boards).length !== 3 || this.state.bottomPieces == null) {
      return;
    }

    this.state.isLoaded = true;
    this.setSwiperReady()
  }

  /* bottom pieces */
  getBottomPieces() {
    // get bottom pieces from warehouse, add new label
    this.wfService.getCategoryProducts(this.playerService.getMePlayerId(), this.episodeData.product_category_id).subscribe(res => {
      const copy: ProductDetails[] = JSON.parse(JSON.stringify(res)) || [];
      // set new label and details to the products of this category
      for (let product of copy) {
        const x = this.episodeData.new_products.find(id => id === product.product_id);
        if (x) {
          product['isNew'] = true;
        }

        product['balance'] = this.state.products[product.product_id]?.balance ?? 0;
      }

      // set currency new label
      for (let currency of this.state.specialCurrencyPieces) {
        const x = this.episodeData.new_currencies.find(id => id === currency.currency_id);
        if (x) {
          currency['isNew'] = true;
        }
      }

      this.state.bottomPieces = copy.sort((a, b) => a.product_id - b.product_id);

      this.markEpisodeAsSeen();
      this.checkIfGameIsReady();
    })
  }

  markEpisodeAsSeen() {
    // check if this is active user
    if (!this.playerService.isActiveMe || this.state.isImpersonated) {
      this.state.isBlocked = true;
      return;
    }

    this.wfService.markWFEpisodeAsSeen(this.episodeData.entry_id).subscribe(() => {})
  }

  // opens the dialog with description
  handlePieceClick(productPiece: ProductDetails | null, currencyPiece: CurrencyBalanceDetails | null) {
    this.dialogService.open(WfPieceDetailsComponent, {
      data: {
        product: productPiece,
        currency: currencyPiece,
      }
    })
  }

  /* swiper */

  // we have to trigger it manually, otherwise it will render incorrectly or/and will have an error (object changed after being checked)
  setSwiperReady() {
    // first initialization
    if (!this.initSwiper) {
      setTimeout(() => {
        this.setSwiper();
        this.state.isLoaded = true;
      }, 10);
      return;
    }

    this.swiper.update();
  }

  swiperPlus() {
    if (this.swiper) {
      this.swiper.slideNext();
    }
  }

  swiperMinus() {
    if (this.swiper) {
      this.swiper.slidePrev()
    }
  }

  // sets the swiper with in the current DOM
  setSwiper() {
    this.initSwiper = false;
    this.swiper = null;
    this.changeDetectorRef.detectChanges();
    this.initSwiper = true;
    this.changeDetectorRef.detectChanges();
    if (this.swiperRef?.nativeElement) {
      this.swiper = this.swiperRef.nativeElement.swiper;
      this.swiper.on("slideChange", swiper => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  // optimization for rendering
  trackByProductId(index: number, item: any) {
    return item.product_id;
  }

  trackByCurrencyId(index: number, item: any) {
    return item.currency_id;
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }

  protected readonly STOCK_VIEW = STOCK_VIEW;
}
