import { Component, OnInit } from "@angular/core";
import { AbstractInjectBaseComponent } from "../../../../../../../../core/abstracts/abstract-inject-base.component";
import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import {WfService} from '../../../../module/custom/wf.service';
import {WfEntry} from '../../../../interfaces/wf.interface';
import * as moment from 'moment';
import {GAME_CONFIG} from '../../../../../../../../core/config/custom/_parsed-game.config';

@Component({
  selector: "wf-main",
  templateUrl: "./wf-main.component.html",
})
export class WfMainComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(MAT_DIALOG_DATA) data: { tab?: string };
  @OwInject(WfService) wfService: WfService;
  isCC = GAME_CONFIG.IS_CC;

  activeTab: TabTop;
  wfSettings: WfEntry[] = null;

  TAB = {
    RULES: "rules",
    LIBRARY: "library",
    RANKS: "ranks"
  };

  tabs: TabTop[] = [
    { label: "Zasady", name: this.TAB.RULES, isSpecial: false, dialogName: 'rules', color: "green", show: true, tooltip: '', enable: true },
    { label: "Biblioteka finałowa", name: this.TAB.LIBRARY, isSpecial: false, dialogName: 'library', color: "green", show: true, enable: false },
    { label: "Rankingi finałowe", name: this.TAB.RANKS, isSpecial: false, dialogName: 'ranks', color: "green", show: true, enable: false },
  ];

  ngOnInit() {
    this.initializeData();
  }

  initializeData(updateValues: boolean = false) {
    if (this.isCC) {
      this.setTab();
      return;
    }

    this.wfService.getWfSettings().subscribe(res => {
      this.wfSettings = res;

      // prepare tabs
      for (let item of this.wfSettings) {
        // set tab const
        const episodeKey = `EPISODE_${item.entry_id}`;

        // tab doesnt exist
        if (this.TAB[episodeKey] == null) {
          this.TAB[episodeKey] = episodeKey.toLowerCase();

          // push tab def
          this.tabs.push({
            label: item.title,
            name: this.TAB[episodeKey],
            settings: item,
            color: item['special'] ? 'orange': "green",
            dialogName: "episode",
            isSpecial: item.special,
            show: true,
            enable: false
          })
        } else {
          // tab doesnt exist
          const tab = this.tabs.find(x => x.name === episodeKey.toLowerCase());

          if (tab) {
            tab.settings = item;
          }
        }
      }

      if (!updateValues) {
        this.setTab();
      }
    })
  }

  get episodeTabs() {
    return Object.values(this.tabs).filter(x => x.dialogName === "episode");
  }

  setTab() {
    if (this.isCC) {
      this.tabs = this.tabs.slice(0, 3);
    }

    this.tabs.map(t => {
      switch (t.name) {
        case this.TAB.LIBRARY:
          t.enable = this.playerService.player.gui_unlocks["wf_media"];
          t.tooltip = t.enable ? '' : 'Dostępne wkrótce';
          break;
        case this.TAB.RANKS:
          t.enable = this.playerService.player.gui_unlocks["wf_ranks"];
          t.tooltip = t.enable ? '' : 'Dostępne wkrótce';
          break;
        case this.TAB.RULES: {
          break;
        }
        default: {
          const wfSetting = t.settings;
          const dateFrom = moment(wfSetting.date_from, 'YYYY-MM-DD');
          const dateTo = moment(wfSetting.date_to, 'YYYY-MM-DD');
          const currDate = moment();

          t.enable = currDate.isSameOrAfter(dateFrom, 'days');
          t.label = wfSetting.title;
          t.tooltip = t.enable ? wfSetting.description : 'Dostępne wkrótce';
          wfSetting.isFinished = moment().isAfter(dateTo, 'days');
         break;
        }
      }
    });
    this.changeTabByName(this.data.tab || this.TAB.RULES);
  }

  changeTabByName(name: string) {
    let tab = this.tabs.find(tab => tab.name === name);

    if (!tab || (tab && !tab.show)) {
      tab = this.tabs.find(tab => tab.show === true);
    }

    this.changeTab(tab);
  }

  changeTab(tab: TabTop) {
    this.activeTab = tab;
  }
}

interface TabTop {
  label: string;
  name: string;
  show: boolean;
  color: string;
  enable: boolean;
  isSpecial: boolean;
  dialogName?: string;
  tooltip?: string;
  settings?: WfEntry;
}
