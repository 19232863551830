import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AbstractInjectBaseComponent } from '../../../../../../../core/abstracts/abstract-inject-base.component';
import { GameService } from '../../../../../services/game.service';
import { AuthService } from '../../../../../../auth/providers/auth.service';
import { AppState } from '../../../../../../../store/state';
import { OwInject } from '../../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../../shared/providers/dialog.service';
import { PlayerService } from '../../../../../../player/providers/player.service';
import { GAME_CONFIG } from '../../../../../../../core/config/custom/_parsed-game.config';
import { EdenredCardListComponent } from '../../dialogs/edenred-card-list/edenred-card-list.component';
import { PLAYER_TITLES } from '../../../../../../../core/consts/core/player-titles';
import { LoginActions } from '../../../../../../../store/auth/login';

@Component({
  selector: 'app-new-edition',
  templateUrl: './new-edition.component.html',
  styleUrls: ['./new-edition.component.scss']
})
export class NewEditionComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(GameService) gameService: GameService;
  @OwInject(AuthService) authService: AuthService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(Store) store: Store<AppState>;

  isCC = GAME_CONFIG.IS_CC;
  PLAYER_TITLES = PLAYER_TITLES;

  ngOnInit() {
    this.gameService.guiService.isSplashShow.next(false);
  }

  openSafe() {
    this.dialogService.open(EdenredCardListComponent);
  }

  logout() {
    this.authService.logoutApi()
      .subscribe(() => {
        this.gameService.guiService.isSplashShow.next(true);
        this.store.dispatch(new LoginActions.AuthLogout());
      });
  }
}
