<div class="global-dialog ow-dialog c-window-long" [ngClass]="activeTab?.dialogName">
  <div class="decoration decoration-1"></div>
  <div class="decoration decoration-2"></div>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <ng-container *ngIf="activeTab">
    <!-- TABS -->
    <div class="tabs">
      <ng-container *ngFor="let tab of tabs; let i = index">
        <div
          *ngIf="tab.show"
          class="tab"
          [class.active]="tab === activeTab"
          [class]="tab.color"
          [style.z-index]="-i"
        >
          <div class="bookmarks">
            <div
              class="bookmark {{tab.color}}"
              [ngClass]="{'disabled': !tab.enable}"
              (click)="tab.enable && changeTab(tab)"
              [matTooltip]="tab.tooltip"
              matTooltipPosition="above"
              matTooltipShowDelay="300"
              [matTooltipDisabled]="tab.enable"
            >
              <span>{{tab.label}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- FRAME CONTENT -->
    <div class="frame">
      <div class="content" [ngSwitch]="activeTab.name" [ngClass]="activeTab.dialogName">
        <ng-container *ngSwitchCase="TAB.RULES" [ngTemplateOutlet]="rulesTpl"></ng-container>
        <ng-container *ngSwitchCase="TAB.LIBRARY" [ngTemplateOutlet]="libraryTpl"></ng-container>
        <ng-container *ngSwitchCase="TAB.RANKS" [ngTemplateOutlet]="ranksTpl"></ng-container>
        <ng-container *ngFor="let tab of episodeTabs">
          <ng-container *ngSwitchCase="tab.name" [ngTemplateOutlet]="mahjongTpl" [ngTemplateOutletContext]="{ data: tab.settings }"></ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<!-- RULES -->
<ng-template #rulesTpl>
    <ng-container *ngIf="!isCC">
      <div class="logo">
        <img [src]="'wf_logo.png'| asset: 'ui'" />
      </div>
      <div class="info">
        <p class="title">Graj w „Duety”!</p>
        <div class="desc">
          Zdobywaj różne kamienie Chi realizując wyzwania biznesowe i biorąc udział w eventach, szukaj ich także na mapie Azji. Za kamienie gromadzisz punkty Chi. Jeśli zdobyte kamienie połączysz w duety z kamieniami znajdującymi się na planszach, to zyskasz dodatkowe punkty!<br><br>
          <b>W Wielkim Finale czekają na Ciebie 3 etapy „Duetów”, a każdy etap to odrębna rozgrywka. Włącz się do rywalizacji i zawalcz o jak najwięcej punktów Chi, aby zająć premiowane miejsce w rankingach finałowych!</b><br><br>
          Dobrze przemyśl swoją strategię łączenia kamieni w duety! Kliknij na planszy w kamień Chi, który chcesz sparować z kamieniem ze swojego zbioru (na pasku pod planszami). Połączone kamienie Chi znikną zarówno z planszy, jak i z Twojego zbioru, ale powiększy się ilość zdobytych punktów Chi. Najszybsi, którzy dobrze rozegrają etap i w całości odkryją obraz przedmiotu łącząc w pary wszystkie kamienie na planszy, zdobywają bonusowy gadżet!<br><br>
          Zapoznaj się z materiałami w poszczególnych zakładkach i przystąp do mistrzowskiej rozgrywki. Zdobywaj kamienie Chi, graj w „Duety” i stań do wyścigu o najwyższe miejsce w rankingach Wielkiego Finału San Tao!
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isCC">
      <div class="logo">
        <img [src]="'wf_cc_logo.png' | asset: 'ui'" />
      </div>
      <div class="info">
        <p class="title">Wielka odpowiedzialność za wielką moc</p>
        <div class="desc">
          Fundamentem filozofii San Tao jest równowaga oparta na energii Chi. Jeżeli w pełni chcesz pojąć sens prastarego azjatyckiego zakonu skup się na tym, by pozyskać jak najwięcej <b>punktów Chi.</b><br><br>
          <b>Realizuj zadania biznesowe</b>, które w Centrum Danych są oznaczone ikoną Chi i zbieraj punkty Chi. Skupiając się na wyzwaniach, pamiętaj o słowach Mistrza Misakiego – <b>równowaga to podstawa.</b> Dlatego w pędzie za biznesem nie zaniedbuj świata San Tao – Twoje zaangażowanie będzie tu nagrodzone. Dodatkowo za <b>zdobyte w grze San Tao punkty doświadczenia</b> również otrzymasz punkt Chi.<br><br>
          <b>Graj, realizuj zadania i wspinaj się w rankingach finałowych.</b> Szczegółowe informacje na temat rozgrywki, punktów i premiowanych miejsc w rankingach znajdziesz w bibliotece finałowej.
        </div>
      </div>
    </ng-container>
</ng-template>

<!-- LIBRARY -->
<ng-template #libraryTpl>
  <wf-media
  [sourceId]="2"
  [isLibrary]="true"
  ></wf-media>
</ng-template>

<!-- RANKS -->
<ng-template #ranksTpl>
  <wf-rank></wf-rank>
</ng-template>

<!-- Mahjong -->
<ng-template #mahjongTpl let-data="data">
  <wf-mahjong [episodeData]="data" (updateEntries)="initializeData(true)"></wf-mahjong>
</ng-template>
