<div class="new-edition-background">
  <div
    *ngIf="!isCC && playerService.player.title_id.id < PLAYER_TITLES.PARTNER.id"
    class="edenred"
    (click)="openSafe()"
    matTooltip="Karta z logo San Tao"
    matTooltipPosition="left"
  ></div>

  <button
    (click)="logout()"
    class="c-square small c-danger no-box-shadow logout"
    matTooltip="Wyloguj"
    matTooltipPosition="left"
  >
    <i class="fas fa-power-off"></i>
  </button>
</div>
