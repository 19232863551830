<div class="wf-mahjong-container loading-opacity" [ngClass]="{'prevent-loading-opacity': state.isLoaded}" >
  <div class="top">
    <div class="points"
         *ngIf="state.points && !episodeData.special; else empty"
         matTooltip="Twoje punkty Chi"
         matTooltipPosition="below"
         matTooltipShowDelay="300">
      <img [src]="'victory_ponits.png' | asset: 'ui'">
      <p>{{ state.points?.balance }}</p>
    </div>
    <p class="date">{{episodeData?.title}}: {{episodeData?.date_from}} do {{episodeData?.date_to}}</p>
  </div>
  <div class="center">
    <div class="mahjong-box" *ngFor="let board of state.decorations.box; let j = index">
      <div class="decorations">
        <div class="inner-box"></div>
        <div *ngFor="let decBox of state.decorations.lines; let i = index" class="corner-box v-{{i}}"></div>
      </div>
      <div class="content"
           [style.background-image]="state.boards?.[state.availableBoards[j]]?.entryData.background ?
           'url(' + (state.boards?.[state.availableBoards[j]]?.entryData.background | asset : 'wf_price') + ')' : ''">
        <ng-container *ngIf="state.boards[state.availableBoards[j]]">
          <div class="piece"
               [ngClass]="{
                 'hidden': piece.hidden,
                 'unclickable': state.isBlocked
               }"
               *ngFor="let piece of state.boards[state.availableBoards[j]]?.tiles" (click)="showPairDialog(piece)">
            <img *ngIf="piece.player_building != null" [src]="piece?.player_building?.icon + '.png' | asset: 'buildings'"/>
          </div>
        </ng-container>
      </div>
      <p class="pieces-left">Pozostało jeszcze {{episodeData?.island_details[j].prize_amount_left}} sztuk</p>
    </div>
  </div>
  <div class="bottom">
    <div class="pieces-container">
      <ng-container *ngIf="(state.specialCurrencyPieces?.length + state.bottomPieces?.length) > 23">
        <div class="nav-btn left" (click)="swiperMinus()" [ngClass]="{'disabled': swiper?.isBeginning}">
          <img [src]="'chevron_big.svg' | asset: 'ui'"/>
        </div>
        <div class="nav-btn right" (click)="swiperPlus()" [ngClass]="{'disabled': swiper?.isEnd}">
          <img [src]="'chevron_big.svg' | asset: 'ui'"/>
        </div>
      </ng-container>

      <div class="relative">
        <swiper-container *ngIf="initSwiper" #swiperRef swiperElement [config]="swiperConfig" init="false">
          <swiper-slide *ngFor="let currency of state.specialCurrencyPieces; trackBy: trackByCurrencyId">
            <div class="piece" (click)="handlePieceClick(null, currency)">
              <div class="new {{currency.isNew ? 'show' : 'hide'}}">NEW</div>
              <img [src]="currency.key + '.png' | asset: 'products/basic'"/>
              <div class="amount {{currency.balance > 1 ? 'show' : 'hide'}}">{{currency.balance }}</div>
            </div>
          </swiper-slide>
          <swiper-slide *ngFor="let piece of state.bottomPieces; trackBy: trackByProductId">
            <div class="piece" (click)="handlePieceClick(piece, null)">
              <div class="new {{piece.isNew ? 'show' : 'hide'}}">NEW</div>
              <img [src]="piece.icon + '.png' | asset: 'products/basic'"/>
              <div class="amount {{piece.balance > 1 ? 'show' : 'hide'}}">{{ piece.balance }}</div>
            </div>
          </swiper-slide>
        </swiper-container>
      </div>

    </div>
  </div>
</div>

<!-- empty points placeholder to keep flex order -->
<ng-template #empty>
  <div class="empty"></div>
</ng-template>
